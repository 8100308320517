import React from 'react';
import styled from 'styled-components';

export default function PageNotFound() {
  return (
    <PageWrap>
      <h5>Microsite Not Available</h5>
    </PageWrap>
  );
}

const PageWrap = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
